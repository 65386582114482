import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';

const postSubscriptions = ({ data = {}, draft = false }) => {
  const path = `/tenants/${APIString.TENANT_SLUG}/stripe/subscriptions/?draft=${draft}`;

  const { account } = store.getState().auth;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const createPaypalSubscription = ({ data = {} }) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paypal_subscriptions/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const confirmPaypalSubscription = (data = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paypal_subscriptions/confirm`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const smsOTPCreate = (data = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/mobifone_9092/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};
export const bankPGCreate = (data = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/bank/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const smsPaymentConfirm = (data = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/mobifone_9092/confirm`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(data),
  );
};

const pgMomoCreate = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/momo/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

const pgMomoConfirm = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/momo/confirm`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export const subscriptionCalc = data => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.reject();
  }
  const authorization = account.access_token ? account.access_token : '';
  const { payment_provider, ...params } = data;
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/${payment_provider}/calc`;
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export const pgVNPayCreate = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/vnpay/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export const pgVNPayConfirm = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/vnpay/confirm`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export const pgVTCPayCreate = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/vtcpay/create`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export const pgVTCPayConfirm = (params = {}) => {
  const { account } = store.getState().auth;
  if (!account) {
    return;
  }
  const accountId = account.profile.id;
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/payment_subscriptions/vtcpay/confirm`;
  const authorization = account.access_token ? account.access_token : '';
  return FETCH(
    path,
    'POST',
    {
      authorization,
      'Content-Type': 'application/json',
    },
    JSON.stringify(params),
  );
};

export {
  postSubscriptions,
  createPaypalSubscription,
  confirmPaypalSubscription,
  smsOTPCreate,
  smsPaymentConfirm,
  pgMomoConfirm,
  pgMomoCreate,
};
