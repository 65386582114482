import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';
import queryString, { stringifyUrl } from 'query-string';
import { getConfigByKey } from '~features/tenantConfig/services';
import { TENANT_NAME_ENUM } from '~core/constants';
import _ from 'lodash';

export { getPlans };

const getPlans = (slug = null) => {
  let paramsQuery = {
    environment: APIString.REACT_APP_ENV,
    tenant_platform: APIString.ROOT_PLATFORM_MENU_ID,
    ...(getConfigByKey('ui_theme') === TENANT_NAME_ENUM.SCTV
      ? { show_multi_months: true, show_mobifone_9029: true }
      : {}),
  };

  if (slug !== null && slug.plan_id !== '' && !_.isEmpty(slug.min_sub_tier)) {
    paramsQuery = {
      ...paramsQuery,
      plan_id: slug.plan_id,
      min_sub_tier: slug.min_sub_tier,
    };
  }

  if (slug !== null && !_.isEmpty(slug.min_sub_tier) && _.isEmpty(slug.plan_id)) {
    paramsQuery = {
      ...paramsQuery,
      min_sub_tier: slug.min_sub_tier,
    };
  }

  paramsQuery = queryString.stringify(paramsQuery);
  const path = `/tenants/${APIString.TENANT_SLUG}/products/?${paramsQuery}`;
  return FETCH(path, 'GET', {});
};

/**
 *
 * @param {string} planId
 */
export const getPlanById = planId => {
  const path = stringifyUrl({
    url: `/tenants/${APIString.TENANT_SLUG}/plans/${planId}`,
    query: {
      environment: APIString.REACT_APP_ENV,
      tenant_platform: APIString.ROOT_PLATFORM_MENU_ID,
    },
  });
  return FETCH(path, 'GET', {});
};
