import FETCH from '../utils/fetch';
import * as APIString from '../constants/apiString';
import { store } from '../store/index';
import { stringifyUrl } from 'query-string';

const loginSSO = (data = {}) => {
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logins/sso`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
      Authorization: data.sso_token,
    },
    JSON.stringify(data),
  );
};

const getPaidContent = () => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (!account) return;
  const accountId = account.profile.id;
  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/${accountId}/paid_contents/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

export const logout = () => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (!account) return;

  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/logout`;
  return FETCH(path, 'POST', {
    Authorization,
  });
};

export const loginSessionActivate = otp => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (!account) return Promise.reject();

  Authorization = account.access_token ? account.access_token : '';
  const path = `/tenants/${APIString.TENANT_SLUG}/accounts/login_sessions/activate/`;
  return FETCH(
    path,
    'POST',
    {
      'Content-Type': 'application/json',
      Authorization,
    },
    JSON.stringify({ code: otp }),
  );
};
export const subscriptionSessionActivate = ({ code, confirm = false }) => {
  const { account } = store.getState().auth;
  const authorization = account?.access_token ?? null;

  const path = stringifyUrl({
    url: `/otp_service/tenants/${APIString.TENANT_SLUG}/subscription_sessions/activate`,
    query: confirm
      ? {
          confirm,
        }
      : {},
  });
  const headers = {
    'Content-Type': 'application/json',
  };
  if (authorization) {
    headers.Authorization = authorization;
  }
  return FETCH(path, 'POST', headers, JSON.stringify({ code }));
};

export { loginSSO, getPaidContent };
