import { store } from '../store/index';
import { startFetch } from './fetch';
import queryString from 'query-string';

import { updateTenantSlug } from '../app/actions';
import * as refreshToken from './refreshToken';
import { getConfigsSuccess } from '../features/tenantConfig/actions';
import {
  ROOT_PLATFORM_MENU_ID,
  ROOT_PLATFORM_MENU_ID_DEFAULT,
  TENANT_SLUG_DEFAULT,
  TENANT_SLUG,
  REACT_APP_TENANT_PLATFORM,
} from '../constants/apiString';
import { checkBlockCountry } from '../features/blockCountry/services';
import { tenantConfig } from '../features/tenantConfig/services';

let buffers = [];
let isRequesting = false;

const ignorePaths = ['allowed_countries/'];

const isIgnoreRequest = request => {
  let result = false;
  // const blockCountryState = store.getState().blockCountryState
  // if (blockCountryState.loaded) {
  //   return false
  // }
  ignorePaths.some(path => {
    if (request.path.indexOf(path) >= 0) {
      result = true;
      return true;
    }
  });
  return result;
};

function setIsRequesting(value) {
  isRequesting = value;
}

export function addBuffer([buffer, { path, method, headers, body }]) {
  if (typeof buffer !== 'function') {
    return;
  }
  buffers.push([buffer, { path, method, headers, body }]);
}

const isRequestRerequireTenant = request => {
  if (isIgnoreRequest(request)) {
    return false;
  }
  return true;
};

function resetBuffers() {
  buffers = [];
}

const fieldsGetDefault = ['email', 'url'];

function doneGetConfigs(response, error) {
  // if (response && !response.feature_advanced_web_multitenancy_enabled) {
  //   fieldsGetDefault.map((name) => {
  //     response[name] = defaultConfigs[name]
  //   })
  // }
  if (response !== null) {
    buffers.some(buffer => {
      const [fetchApi, options] = buffer;
      if (typeof fetchApi !== 'function') {
        return;
      }
      nextStepHandle(fetchApi, options);
    });
  }
  resetBuffers();
  setIsRequesting(false);
}

function nextStepHandle(buffer, options) {
  let newPath = '';
  const { platform_slug = ROOT_PLATFORM_MENU_ID_DEFAULT, tenant_slug = TENANT_SLUG_DEFAULT } =
    tenantConfig;

  newPath = options.path
    .replace(ROOT_PLATFORM_MENU_ID, platform_slug)
    .replace(encodeURI(ROOT_PLATFORM_MENU_ID), platform_slug)
    .replace(TENANT_SLUG, tenant_slug)
    .replace(encodeURI(TENANT_SLUG), platform_slug);
  options = {
    ...options,
    path: newPath,
  };
  refreshToken.checkRefreshToken(buffer, options);
}

export function checkTenantConfigs(buffer, options) {
  if (typeof buffer !== 'function') {
    return;
  }
  const isRequire = isRequestRerequireTenant(options);
  if (isRequire && !isRequesting) {
    setIsRequesting(true);
    checkBlockCountry()(store.dispatch)
      .then(response => {
        doneGetConfigs(tenantConfig, null);
      })
      .catch(error => {
        doneGetConfigs(null, error);
      });
  }
  if (isRequire && isRequesting) {
    addBuffer([buffer, options]);
    return;
  }
  nextStepHandle(buffer, options);
}

function getTenantConfigs() {
  let hostname = window.location.hostname;
  if (hostname === 'localhost') {
    hostname = 'hotfix.sctv.vimai.io';
  }
  let params = {
    domain: hostname,
  };
  const queryParams = queryString.stringify(params);
  const path = `/backend/cm/tenant_slug_from_domain?${queryParams}`;
  const header = {
    'Content-Type': 'application/json',
  };
  return new Promise((resolve, reject) => {
    const dispatch = store.dispatch;
    startFetch(path, 'GET', header)
      .then(response => {
        dispatch(updateTenantSlug(response));
        let timer = setTimeout(() => {
          resolve(response);
          clearTimeout(timer);
        }, 100);
      })
      .catch(error => {
        dispatch(updateTenantSlug(false));
        reject(error);
      });
  });
}

function requestSlugFromDomain() {
  let hostname = window.location.hostname;
  if (hostname === 'localhost') {
    hostname = 'hotfix.sctv.vimai.io';
  }
  let params = {
    domain: hostname,
  };
  const queryParams = queryString.stringify(params);
  const path = `/backend/cm/tenant_slug_from_domain?${queryParams}`;
  const header = {
    'Content-Type': 'application/json',
  };
  return new Promise((resolve, reject) => {
    const dispatch = store.dispatch;
    startFetch(path, 'GET', header)
      .then(response => {
        dispatch(updateTenantSlug(response));
        let timer = setTimeout(() => {
          resolve(response);
          clearTimeout(timer);
        }, 100);
      })
      .catch(error => {
        dispatch(updateTenantSlug(false));
        reject(error);
      });
  });
}

export default requestSlugFromDomain;
