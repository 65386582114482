import moment from 'moment';
import { localStorage as ls } from 'js-storage';

export const storeExpire = (nameKey, durationExpire = 5) => {
  if (!nameKey) {
    return;
  }
  const expireTimeAt = moment().add(durationExpire, 'seconds');

  ls.set(nameKey, expireTimeAt.toISOString());
};

export const isExpire = nameKey => {
  const expireAt = ls.get(nameKey);

  if (!expireAt) {
    return true;
  }

  if (moment(expireAt).isAfter(moment())) {
    return false;
  }
  return true;
};
