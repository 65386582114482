import * as API_STRING from '../constants/apiString';

import * as refreshToken from './refreshToken';
import { checkTenantConfigs } from './getSlugDomain';
import isomorphicFetch from 'isomorphic-fetch';
import { store } from '~store';
import { logout } from '~features/auth/services';
import { FORCE_LOGOUT_MESSAGE, actions } from '~components/alertPopup/alertPopupSlice';
import { history } from '~store/history';
import { CUSTOM_X_USER_AGENT, getCustomUserAgent } from './utils';

const { dispatch } = store;

const FORCE_LOGOUT_ERROR_CODE = 'session_invalidated_because_force_logout';

const handleForceLogout = () => {
  dispatch(actions.setData(FORCE_LOGOUT_MESSAGE));
  dispatch(logout())
    // .then(() => )
    .catch(error => {
      console.log(error);
    });
};

const errorCustom = error => {
  let msg = error.message;
  if (typeof msg !== 'string' && Object.values(error.message).length) {
    msg = Object.values(error.message)
      .reduce((result, current) => {
        if (Array.isArray(current)) {
          current.map(message => {
            result.push(message);
          });
        } else {
          result.push(current);
        }

        return result;
      }, [])
      .join('\n');
    error.message = msg;
  }

  return error;
};

export default function FETCH(path, method, headersExtra = {}, body) {
  let headers = { ...headersExtra };
  const { tenantSlug } = store.getState().root;
  const { account } = store.getState().auth;

  headers = Object.assign(headers, {
    'X-User-Agent': CUSTOM_X_USER_AGENT,
  });
  if (account && account.profile && account.profile.id) {
    headers = Object.assign(headers, {
      'X-Trace-Account-Id': account.profile.id,
    });
  } else {
    headers = Object.assign(headers, {
      'X-Trace-Account-Id': 'anonymous',
    });
  }
  return new Promise(function (resolve, reject) {
    // if (tenantSlug) {
    //   path = pathUrl(path)
    // }
    const startF = ({ path, method, headers, body }) => {
      startFetch(path, method, headers, body)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    };
    checkTenantConfigs(startF, { path, method, headers, body });
    // refreshToken.checkRefreshToken(startF, { path, method, headers, body })
  });
}

function pathUrl(path) {
  const { tenantSlug } = store.getState().root;
  const newPath = new RegExp(/tenants\/?(\/.*)?$/gm);
  let checkPath = newPath.test(path);
  let arrayPath = path.split('/');
  if (checkPath) {
    arrayPath.find((item, index) => {
      if (item === API_STRING.TENANT_SLUG) {
        arrayPath[index] = tenantSlug.tenant_slug;
        path = arrayPath.join('/');
        return true;
      }
    });
  }
  return path;
}
function startFetch(path, method, headers, body) {
  return new Promise((resolve, reject) => {
    const { language } = store.getState().root;
    const regLink = new RegExp('^https?.+');
    const HEADERS = {
      'Accept-Language': language || 'vi',
    };
    const timeout = setTimeout(() => {
      reject(new Error('Request API timeout, please try again later.'));
    }, 60000);

    const fetchURL = regLink.test(path) ? path : `${API_STRING.BASE_URL}${path}`;
    isomorphicFetch(fetchURL, {
      method,
      headers: {
        ...HEADERS,
        ...headers,
      },
      body,
    })
      .then(response => {
        clearTimeout(timeout);

        if (response.status >= 400 && response.status < 500) {
          return response.json().then(data => {
            const { message, detail } = data;
            data.message = message ?? detail;
            if (data && data.message) {
              const error = errorCustom(data);

              if (data.error_code === 'country_blocking_exception') {
                if (response.headers.has('x-request-id')) {
                  data['x_request_id'] = response.headers.get('x-request-id');
                }
                throw data;
              } else if (data.error_code === 'REFRESH_TOKEN_EXPIRED') {
                throw data;
              } else {
                throw error;
              }
            } else {
              return reject(Error('Có lỗi xảy ra khi kết nối đến hệ thống, vui lòng thử lại.'));
            }
            // throw new Error(JSON.stringify(data));
          });
        }
        if (response.status >= 500 && !response.ok) {
          return reject(Error('Có lỗi xảy ra khi kết nối đến hệ thống, vui lòng thử lại.'));
        }
        return response.json();
      })
      .then(responseJson => {
        if (responseJson.error) {
          reject(responseJson);
        } else {
          resolve(responseJson);
        }
      })
      .catch(error => {
        if (error?.error_code === FORCE_LOGOUT_ERROR_CODE) {
          handleForceLogout(error);
        }
        if (error.message === 'Failed to fetch') {
          error.message = 'Có lỗi xảy ra khi kết nối đến hệ thống, vui lòng thử lại.';
        }
        reject(error);
      });
  });
}

export { pathUrl, startFetch };
