export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const REGISTER_LOGIN_SUCCESS = 'REGISTER_LOGIN_SUCCESS';
export const UPDATE_FIRSTNAME = 'UPDATE_FIRSTNAME';
export const UPDATE_PLAN_SELECTED = 'UPDATE_PLAN_SELECTED';
export const RESET_DATA_FORM_PLAN_SELECTED = 'RESET_DATA_FORM_PLAN_SELECTED';
export const UPDATE_DATA_BOX_SELECTED = 'UPDATE_DATA_BOX_SELECTED';
export const RESET_DATA_FORM_BOX_SELECTED = 'RESET_DATA_FORM_BOX_SELECTED';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const RESET_ADDRESS = 'RESET_ADDRESS';
export const IS_LOADING = 'IS_LOADING';
export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';
export const SET_TRANSACTION_ID = 'SET_TRANSACTION_ID';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_PRICE_PG = 'SET_PRICE_PG';
export const SET_PAYMENT_PROVIDER = 'SET_PAYMENT_PROVIDER';
export const SET_CALCULATION = 'SET_CALCULATION';
export const RESET_FORM_SUBSCRIPTION = 'RESET_FORM_SUBSCRIPTION';
