import React from 'react';

export const ExtraGeoBlockingInformation = ({ information }) => {
  const preparedTableData = () => {
    const { extra_args, x_request_id } = information;
    const tableData = [
      {
        label: 'IP:',
        value: extra_args?.client_ip,
      },
      {
        label: 'Quốc gia:',
        value: extra_args?.country,
      },
    ];
    if (x_request_id) {
      tableData.push({
        label: 'Request trace id:',
        value: x_request_id,
      });
    }
    return tableData;
  };

  if (!information) {
    return null;
  }
  return (
    <div className="mx-auto rounded-[20px] border border-[#8c8c8c] px-[10px] py-[13px] font-open-sans md:w-[min(633px,100%)] md:px-[70px] md:py-[30px]">
      <table>
        {preparedTableData().map(row => (
          <tr key={row.label}>
            <td className="normal whitespace-nowrap py-[4px] pr-[12px] align-top text-[14px] normal-case text-[#d4d4d4] md:py-[9px] md:pr-[60px] md:text-[18px]">
              {row.label}
            </td>
            <td className=" break-all py-[4px]  text-[14px] font-bold normal-case text-[#fff2f2] md:py-[9px] md:text-[22px]">
              {row.value}
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
