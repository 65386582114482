import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { App } from './app/views';
import AlertPopup from '~components/alertPopup/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { routePageNames as routes } from '~core/routes';
import { pathToUrl } from '~utils/routes';
import { ScrollToTop } from '~components/ScrollToTop/ScrollToTop';
import { getProfile } from '~features/profile/services';

export default props => {
  const account = useSelector(state => state.auth.account);
  const blockCountry = useSelector(state => state?.blockCountry);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const refetchProfile = useCallback(() => {
    if (!account) {
      return;
    }

    getProfile({ refresh: true })(dispatch);
  }, [account]);
  useEffect(() => {
    if (blockCountry.isBlock && location.pathname !== routes.blockCountry) {
      history.push(pathToUrl(routes.blockCountry));
    }
  }, [history, blockCountry, location]);

  useEffect(() => {
    refetchProfile();
  }, [location]);

  return (
    <App
      {...props}
      // key={account?.profile?.id}
    >
      <AlertPopup />
      <ScrollToTop />
    </App>
  );
};
