export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;
export const LIVE_EVENT = 9;
export const TRAILER_TYPE = 8;
export const SIMULATED_LIVE_EVENT_TYPE = 10;

export const ALL_LIVE_TYPE = [CURATED_LIVE, CHANNEL_TYPE, LIVE_EVENT, SIMULATED_LIVE_EVENT_TYPE];

export const TYPE_NO_LANDING_PAGE = [...ALL_LIVE_TYPE];

export const CONTENT_TYPE_STRING_MAP = new Map([
  [MOVIE_TYPE, 'movie'],
  [EPISODE_TYPE, 'episode'],
  [CLIP, 'clip'],
  [TRAILER_TYPE, 'trailer'],
  [CHANNEL_TYPE, 'channel'],
  [CURATED_LIVE, 'curated_live_channel'],
  [SIMULATED_LIVE_EVENT_TYPE, 'simulated_live_event'],
  [LIVE_EVENT, 'live_event'],
]);
