import React, { Component, memo } from 'react';
import * as Version from '../../constants/envConfig';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DowloadApp from './dowloadApp';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Box, Icon } from '@material-ui/core';
import { getConfigByKey } from '~features/tenantConfig/services';

const privacyPolicyLink = getConfigByKey('privacyPolicyLink');
const termsOfUseLink = getConfigByKey('termsOfUseLink');

const BoxStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  align-items: center;
  .app-download {
    margin-right: -5px;
    margin-top: 18px;
  }
  .left,
  .mid,
  .right {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .right {
    padding-bottom: 4px;
    /* text-align: right; */
  }
  .icon-link {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
    span {
      color: #8a8a8a;
    }
    .fa {
      color: #e30000;
      /* padding-right: 20px; */
      max-width: 25px;
      width: 25px;
      display: inline-flex;
      justify-content: center;
    }
  }
  .space {
    padding: 0 1rem;
  }
  .text {
    &--white {
      color: #d6d6d6;
    }
    &--grayer {
      color: #d8d8d8;
    }
    &--biggest {
      font-size: 23px;
      font-size: 20px;
    }
    &--big {
      font-size: 18px;
      font-size: 15px;
    }
    &--medium {
      font-size: 14px;
      font-size: 11px;
    }
    &--smallest {
      font-size: 12px;
      font-size: 9px;
    }
  }
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
    .left,
    .mid,
    .right {
      /* flex-grow: 1; */
      flex-basis: 33%;
      text-align: left;
    }
    .mid {
      text-align: center;
      &--bot {
        margin-top: 23px;
      }
    }
    .right {
      text-align: right;
    }
    .text {
      &--biggest {
        font-size: 23px;
      }
      &--big {
        font-size: 18px;
      }
      &--medium {
        font-size: 14px;
      }
      &--smallest {
        font-size: 12px;
      }
    }
  }
`;

function FooterSctv2() {
  const { t } = useTranslation();
  return (
    <BoxStyled className="footer-sctv2">
      <Box className="left">
        <Box className="text--biggest text--white">Liên lạc</Box>
        <Box>
          <a className="text--big text--white icon-link" href="tel:19001878">
            <i className="fa fa-phone text--big" />
            <span>:</span> 19001878
          </a>
        </Box>
        <Box>
          <a className="text--big text--white icon-link" href="mailto:sctvonline@sctv.vn">
            <i className="fa fa-envelope-o text--big" />
            <span>:</span> sctvonline@sctv.vn
          </a>
        </Box>
        <Box>
          <a className="text--big text--white icon-link" href="#">
            <i className="fa fa-map-marker text--big" />
            <span>:</span> 31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM
          </a>
        </Box>
      </Box>
      <Box className="mid">
        <Box>
          <Link
            className="text--medium text--grayer"
            // target="_blank"
            to={privacyPolicyLink}
          >
            {t('subscriptions.privacy policy')}
          </Link>
          <span className="space" />
          <Link
            className="text--medium text--grayer"
            // target="_blank"
            to={termsOfUseLink}
          >
            {t('subscriptions.box streaming')}
          </Link>
        </Box>
        <Box className="text--medium text--grayer mid--bot">
          {/* © 2012-2020 SCTV online. Design Reg. U.S. Pat. & Tm. Off. */}
        </Box>
      </Box>
      <Box className="right text--smallest text--grayer">
        <Box>
          Tải xuống ứng dụng SCTVOnline <br /> trong App Store để có trải nghiệm tốt hơn
        </Box>
        <Box>
          <DowloadApp />
        </Box>
      </Box>
    </BoxStyled>
  );
}

export default memo(FooterSctv2);
