import * as types from './actionTypes';

export {
  changeProfileSuccess,
  registerLogin,
  updateFirstName,
  updateDataPlanSelected,
  resetDataPlanSelected,
  updateDataBoxSelect,
  resetDataBoxSelect,
  updateAddress,
  resetAddress,
  loading,
};
function loading(payload) {
  return {
    type: types.IS_LOADING,
    payload,
  };
}

function resetAddress() {
  return {
    type: types.RESET_ADDRESS,
  };
}

function updateAddress(payload) {
  return {
    type: types.UPDATE_ADDRESS,
    payload,
  };
}

function updateDataPlanSelected(payload) {
  return {
    type: types.UPDATE_PLAN_SELECTED,
    payload,
  };
}

function resetDataPlanSelected(payload) {
  return {
    type: types.RESET_DATA_FORM_PLAN_SELECTED,
    payload,
  };
}

function updateDataBoxSelect(payload) {
  return {
    type: types.UPDATE_DATA_BOX_SELECTED,
    payload,
  };
}

function resetDataBoxSelect(payload) {
  return {
    type: types.RESET_DATA_FORM_BOX_SELECTED,
    payload,
  };
}
function changeProfileSuccess(account) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    account,
  };
}

function registerLogin(account) {
  return {
    type: types.REGISTER_LOGIN_SUCCESS,
    account,
  };
}

function updateFirstName(first_name) {
  return {
    type: types.UPDATE_FIRSTNAME,
    first_name,
  };
}

export const setPreviousLocation = payload => {
  return {
    type: types.SET_PREVIOUS_LOCATION,
    payload,
  };
};

export const setTransactionId = payload => {
  return {
    type: types.SET_TRANSACTION_ID,
    payload,
  };
};

export const setTotal = payload => {
  return {
    type: types.SET_TOTAL,
    payload,
  };
};

export const setPricePG = payload => {
  return {
    type: types.SET_PRICE_PG,
    payload,
  };
};

export const setPaymentProvider = payload => {
  return {
    type: types.SET_PAYMENT_PROVIDER,
    payload,
  };
};

export const setCalculations = payload => {
  return {
    type: types.SET_CALCULATION,
    payload,
  };
};
export const resetFormSubscription = () => {
  return {
    type: types.RESET_FORM_SUBSCRIPTION,
  };
};
