import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { setUserProperties } from '../../../services/analytics/SetUserProperties';
import { getConfigByKey } from '../../../features/tenantConfig/services';
import classnames from 'classnames';
import clsx from 'clsx';

export default class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: null,
    };
  }

  componentDidMount() {
    const { logo } = this.state;
    if (!logo && getConfigByKey('nav_bar_logo')) {
      this.setState({
        logo: getConfigByKey('nav_bar_logo'),
      });
    }
  }

  render() {
    const { logo } = this.state;
    const { className, small } = this.props;
    return (
      logo && (
        <Styled className={clsx('wrap-logo', { small: small })}>
          <Link
            className={classnames('v-logo', className)}
            to={'/'}
            onClick={() => setUserProperties()}
          >
            <img src={logo} width="160" height="50" />
          </Link>
        </Styled>
      )
    );
  }
}

const Styled = styled.div`
  .v-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    img {
      width: 103px;
      max-width: 7rem;
      height: auto;
    }
  }
  &.small {
    img {
      width: 80px;
    }
  }

  @media (min-width: 768px) {
    .v-logo {
      img {
        width: 150px;
        max-width: ${({ theme }) => theme.header.imglogo};
      }
    }
    &.small {
      img {
        width: 110px;
      }
    }
  }

  @media (max-width: 1024px) and (orientation: landscape) {
    .v-logo {
      display: block !important;
    }
  }
`;
