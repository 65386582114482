import { pathToUrl } from '~utils/routes';

export const routePageNames = {
  home: '/',
  tenantBySlug: '/page/:slug',
  accountConfirm: '/account/confirmation',
  loginSSO: '/auth/login/sso',
  termOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  contentDetailBySlug: '/detail/:slug',
  contentLandingBySlug: '/landing/:slug',
  contentLiveBySlug: '/live/:slug',
  ribbonDetailBySlug: '/ribbon/:slug',
  favorite: '/favorite',
  searchByKeyword: '/search/:keyword',
  searchByKeywordInOther: '/search/:keyword/page/:page',
  profileTab: '/profile/:id',
  help: '/help',
  faqSlug: '/faq/:slugBlockAds',
  faq: '/faq',
  categoryBySlug: '/category/:slug',
  passwordChange: '/password/change',
  blockCountry: '/block',
  tvSignInUp: '/:tvMethod(tv-login|tv-signup)',
  tvLoginSuccessfully: '/tv-login-successfully',
  subscriptionChange: '/subscription',
  validateCode: '/validate-code',
  paymentPlan: '/:lg/box/:plan',
};

export const generateTvLogin = () => pathToUrl(routePageNames.tvSignInUp, { tvMethod: 'tv-login' });
