// THIS FILE IS DEPRECATED, PLEASE USE constants.tenantConfig.* instead
// TODO: Remove this file
const TENANT_CONFIG = {
  odv: {
    BANNER_STYLE: '',
    VERTICAL_RIBBON: true,
    CUE_POINT_ADS: true,
    RIBBON_RENDER_EP_NAME: false,
    RIBBON_COL_SIZE: '',
    POSTER_SIZE: 'medium',
    HOME_RENDER_FILTER: true,
    GTM: true,
  },
  sctv: {
    BANNER_STYLE: 'fullscreen',
    VERTICAL_RIBBON: false,
    CUE_POINT_ADS: true,
    RIBBON_RENDER_EP_NAME: true,
    RIBBON_COL_SIZE: 'small',
    POSTER_SIZE: 'small',
    HOME_RENDER_FILTER: false,
    GTM: false,
  },
};

export default TENANT_CONFIG;
