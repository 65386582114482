import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const BoxStyled = styled(Box)`
  .app {
    padding: 0 0.5rem;
  }
  display: flex;
  justify-content: flex-end;
`;

export const DowloadApp = () => {
  return (
    <BoxStyled className="app-download">
      <a className="app" target="_blank" href="https://apps.apple.com/vn/app/sctv/id1564652065">
        <img src={require(`../../assets/img/appStoreIcon.svg`)} />
      </a>
      <a
        className="app"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=io.vimai.sctvonline"
      >
        <img src={require(`../../assets/img/googlePlayIcon.svg`)} />
      </a>
    </BoxStyled>
  );
};

export default DowloadApp;
