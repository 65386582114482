import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Col, Row } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import FooterV2 from '../../footer/footerV2';
import styled from 'styled-components';
import { checkGeoBlocking } from '../../../app/services';
import { getConfigByKey, tenantConfig } from '../../tenantConfig/services';
import { setUserProperties } from './../../../services/analytics/SetUserProperties';
import { Link } from 'react-router-dom';
import { hideMenu, showMenu } from '~components/global/globalServices';
import { ExtraGeoBlockingInformation } from '~components/geoBlocking/ExtraGeoBlockingInformation';
import smallerLogo from '~assets/img/sctv/smaller-sctv-logo.png';
import Logo from '~components/header/components/logo';

class BlockCountry extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);
    this.myInterval = null;
    this.state = {
      logo: getConfigByKey('nav_bar_logo'),
    };
  }
  componentDidMount() {
    const { blockRegionSubscription } = this.props;
    if (blockRegionSubscription) {
      return;
    }
    this._checkBlock();
  }

  componentWillMount() {
    const { dpHideMenu } = this.props;
    dpHideMenu();
  }

  componentWillUnmount() {
    const { dpShowMenu } = this.props;
    dpShowMenu();
  }

  componentWillReceiveProps(nextProps) {
    const { logo } = this.state;
    if (!logo && getConfigByKey('nav_bar_logo')) {
      this.setState({
        logo: getConfigByKey('nav_bar_logo'),
      });
    }
  }

  _checkBlock() {
    const { dispatchCheckGeoBlocking } = this.props;
    return;
  }

  render() {
    const { t, blockRegionSubscription, geoBlockingError } = this.props;
    const { logo } = this.state;
    let text = {
      txtDescription: blockRegionSubscription
        ? t('box.txtBlockContrySubscription')
        : t('box.sorry', {
            site_name: getConfigByKey('site_name'),
            tenantName: getConfigByKey('name'),
          }),
    };
    return (
      <BlockCountryStyled className="block-container background flex flex-col justify-center">
        <div className="mx-auto mt-[5rem] md:w-[min(800px,100%)]">
          <RowStyled>
            <Logo className="v-block--logo small" />

            <Col className="v-block--col">
              <Link to={'/'} onClick={() => setUserProperties()} className="mb-[2rem] md:hidden">
                <img src={smallerLogo} width={'150px'} className="mx-auto" />
              </Link>
              <p className="v-block--title" style={{ fontWeight: 'bold' }}>
                {getConfigByKey('site_name')}
              </p>
              <p className="v-block--description">Xem Phim Theo Ý Bạn</p>
              <p className="v-block--sr" style={{ textTransform: 'none', fontStyle: 'Italic' }}>
                {text.txtDescription}
              </p>
              <div className="v-block--footer" style={{ textTransform: 'none' }}>
                {/* <p>{t('subscriptions.footer.ContactUs')}</p> */}
                <p className="md:text-[23px]">Chi tiết liên hệ</p>
                <a
                  href={`tel:${getConfigByKey('phone_number_1')}`}
                  style={{ cursor: 'pointer', color: '#2574d4' }}
                >
                  <i className="fa fa-phone" aria-hidden="true" style={{ color: '#8a8a8a' }}></i>
                  {` `}
                  {getConfigByKey('phone_number_1')}
                </a>

                <a
                  href={`mailto:${getConfigByKey('email')}`}
                  style={{ cursor: 'pointer', color: '#2574d4' }}
                >
                  <i
                    className="fa fa-envelope-o"
                    aria-hidden="true"
                    style={{ color: '#8a8a8a' }}
                  ></i>{' '}
                  {t('subscriptions.footer.email', { email: getConfigByKey('email') })}
                </a>
              </div>
              <p className="v-block--address">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
                {` `}31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM
              </p>
            </Col>

            <Link
              className="v-block--home"
              style={{ display: !blockRegionSubscription && 'none' }}
              to={'/'}
              onClick={() => setUserProperties()}
            >
              <i className="fa fa-angle-double-left" /> {t('box.txtHome')}
            </Link>
          </RowStyled>
          <ExtraGeoBlockingInformation information={geoBlockingError} />
        </div>
      </BlockCountryStyled>
    );
  }
}

const BlockCountryStyled = styled.div`
  &.block-container {
    display: flex;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    font-family: Open Sans;
    min-height: 100dvh;
  }
  &.background {
    padding-inline: 1rem;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%),
      url(${require('~assets/img/sctv/bg-block-country.jpg')});
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media (min-width: 768px) {
    &.background {
      background-image: url(${require('~assets/img/sctv/bg-block-country.jpg')});
    }
  }
`;

const RowStyled = styled.div`
  font-family: 'Open sans';
  margin-bottom: 30px;

  &.v-block {
  }
  .fa {
    font-size: 16px;
  }
  .v-block {
    &--logo {
      object-fit: cover;
      position: absolute;
      top: 19px;
      left: 15px;
      z-index: 9;
      img {
        max-width: 7rem;
      }
    }
    &--footer {
      padding-top: 20px;
      margin-top: 12px;
      display: flex;
      text-align: center;
      font-size: 14px;
      position: relative;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 9px;
      &::before {
        content: '';
        width: 90%;
        position: absolute;
        border-top: 0.5px solid #666666;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }
      p {
        margin: 0;
      }
    }
    &--title {
      font-size: 40px;
      text-align: center;
      text-transform: initial;
      font-weight: bold;
      margin-bottom: 0;
    }
    &--description {
      font-size: 25px;
      text-align: center;
      text-transform: capitalize;
    }
    &--sr {
      font-size: 16px;
      text-align: center;
    }

    &--col {
      width: 100%;
    }

    &--address {
      text-align: center;
      color: #999999;
      text-transform: initial;
    }
    &--home {
      position: fixed;
      color: #fff;
      left: 15px;
      bottom: 27px;
      text-transform: capitalize;
      font-size: 1.25rem;
      display: none;
      :hover {
        text-decoration: none;
      }
    }
  }
  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    margin-bottom: 45px;

    .v-block {
      &--logo {
        left: 45px;
        img {
          max-width: 11rem;
        }
      }
      &--title {
        font-size: 100px;
      }
      &--description {
        font-size: 40px;
      }
      &--sr {
        font-size: 35px;
      }
      &--footer {
        justify-content: center;
        column-gap: 1rem;
        font-size: 18px;
        margin-bottom: 13px;
        div {
          padding-right: 1.5rem;
          margin-bottom: 0rem;
        }
        font-size: 1rem;
      }
      &--adress {
        display: block;
      }
      &--home {
        left: 45px;
        display: block;
      }
    }
  }

  @media (max-width: 812px) and (orientation: landscape) {
    .v-block {
      &--adress {
        display: none;
      }
    }
  }
`;

BlockCountry.defaultProps = {
  showLoginAction: () => {},
  showForgotPassAction: () => {},
};

const mapStateToProps = state => ({
  account: state.auth.account,
  isBlock: state.root.isBlock,
  geoBlockingError: state.blockCountry.error,
});

const mapDispatchToProps = {
  dispatchCheckGeoBlocking: () => checkGeoBlocking(),
  dpHideMenu: () => hideMenu(),
  dpShowMenu: () => showMenu(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BlockCountry)),
);
